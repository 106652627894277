@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');



body {
    box-sizing: border-box;
    margin: 0 auto;
    width: 100vw;
    text-align: center;
    font-family: 'Roboto Mono', Arial, sans-serif;
    font-size: 20px;
    background-color: rgb(240, 240, 240);
    color: rgb(49, 54, 56);
}

img {
    margin: 0 auto;
    max-width: 75vw;
}

nav {
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 15px;
    min-height: 180px;
}

p {
    margin: 0 auto;
    text-align: left;
    line-height: 1.5rem;
}

h2 {
    font-size: 2rem;
}

ul {
    list-style-type: none;
    display: inline-block;
    margin: 0 auto;
    width: 200px;
    padding: 20px;
}

.nav-img {
    min-width: 20px;
    max-width: 40px;
    width: 3vw;
    height: auto;
    margin: 15px auto 0 auto;
    display: inline-block;
}

.link,
a {
    text-decoration: none;
    color: rgb(126, 168, 190);
}

.link:visited,
a:visited {
    color: rgb(120, 113, 170);
}

.link:active,
a:active {
    color: rgb(203, 133, 137);
}

.dark {
    background-color: rgb(49, 54, 56);
    color: rgb(240, 240, 240);
}

.dark .link,
.dark a {
    color: rgb(118, 148, 167);
}

.mode {
    min-width: 20px;
    max-width: 40px;
    width: 3vw;
    height: auto;
    padding: 0;
    background-color: rgb(93, 105, 100);
    color: rgb(49, 54, 56);
    border: none;
}

.mode:hover {
    cursor: pointer;
}

.mode:focus {
    outline: 0;
}

.primary {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 50vh;
}

.section-heading {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(129, 156, 139);
    text-shadow: -4px -2px rgb(49, 54, 56);
    scroll-snap-align: start;
}

.project {
    min-height: 100vh;
    scroll-snap-align: start;
}

.project img {
    padding-top: 4vh;
    padding-bottom: 4vh;
    max-height: 70vh;
}

.title-fade-out {
    opacity: 0;
    animation-name: fade-out;
    animation-duration: 2s;
}

.title-fade-in {
    opacity: 1;
    animation-name: fade-in;
    animation-duration: 2s;
}

#title {
    height: 100vh;
    font-size: 2rem;
    display: flex;
    vertical-align: center;
    justify-content: center;
    align-items: center;
}

#skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    min-height: 100vh;
}

#app {
    position: absolute;
    display: grid;
    grid-template-columns: 14vw 40vw 40vw;
    column-gap: 3vw;
}

#sidebar {
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 2;
    background-color: rgb(93, 105, 100);
    align-items: center;
}

.main {
    grid-column-start: 2;
    grid-column-end: 4;
    justify-self: end;
    scroll-snap-type: proximity;
    scroll-snap-type: y proximity;
}

#logo {
    max-width: 17vw;
    height: auto;
    width: auto;
    display: inline-block;
    margin-bottom: 2vh;
    margin-left: 15px;
    padding: 3vh 1vw;
    background-color: rgb(49, 54, 56);
    border: 3px solid rgb(93, 105, 100);

}

#zen {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#zen p {
    font-size: 2rem;
    line-height: 3rem;
}

#skills-section {
    scroll-snap-type: y mandatory;
}

#projects-section {
    scroll-snap-type: y mandatory;
}

@media screen and (min-width: 600px) {
    img {
        max-width: 82vw;
    }

    .project div {
        display: flex;
    }

    .project div img {
        padding-top: 4vh;
        padding-bottom: 4vh;
        max-width: 40vw;
    }

    .project div p {
        padding: 4vh 4vw;
        grid-column-start: 2;
        justify-content: center;
        align-self: center;
    }

    .section-heading h2 {
        font-size: 3rem;
    }

    #app {
        position: absolute;
        display: grid;
        grid-template-columns: 6vw 45vw 45vw;
        column-gap: 2vw;
    }

    #logo {
        max-width: 7vw;
        padding: 3vh 1vw;
        margin-left: 2vw;
    }

    #title {
        font-size: 3rem;
    }
}

@keyframes fade-out {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fade-in {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}